<template>
  <div>
    <v-container class="common-page__container">
      <div v-if="!resetPasswordSent">
        <v-row>
          <v-col cols="12">
            <h2 class="my-6">
              Enter your email to get a link for resetting your password
            </h2>
          </v-col>
        </v-row>
        <validation-observer v-slot="{ invalid }">
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{errors}"
                name="email"
                rules="required|email"
              >
                <div class="login-input-label__wrapper my-3">
                  <label
                    for="email"
                    class="login-label"
                  >Email
                  </label>
                  <input
                    id="email"
                    v-model="email"
                    type="email"
                    class="login-input"
                    @focus="errorText=''"
                    @keydown.enter="resetPassword"
                  >
                </div>
                <p
                  v-if="errors[0]"
                  class="login-error__text text-center mt-5"
                >
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row v-if="errorText">
            <v-col class="text-center">
              <p class="login-error__text mb-2">
                {{ errorText }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <custom-button
                text="Send"
                :disabled="invalid"
                @click="resetPassword"
              />
            </v-col>
          </v-row>
        </validation-observer>
      </div>
      <div v-else>
        <v-row>
          <v-col>
            <h2>Ckeck your inbox</h2>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div class="common-background__svg">
      <img :src="backgroundImage">
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { sendResetPasswordEmail } from '@/authentication.js'
import { mapMutations } from 'vuex'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
  components: { CustomButton },

  props: {
    backgroundImage: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    email: '',
    errorText: '',
    resetPasswordSent: false
  }),

  mounted () {
    const analytics = getAnalytics()
    logEvent(analytics, 'ForgotPasswordPage')
  },

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    }),

    async resetPassword () {
      this.setWaitForJobCompletion(true)
      try {
        const value = await sendResetPasswordEmail(this.email)
        if (value) this.resetPasswordSent = true
      } catch (error) {
        this.errorText = error
      }
      this.setWaitForJobCompletion(false)
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
