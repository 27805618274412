<template>
  <div>
    <v-container class="common-page__container">
      <v-row>
        <v-col cols="12">
          <h2>Welcome back</h2>
        </v-col>
      </v-row>
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{errors}"
              name="email"
              rules="email|required"
            >
              <div class="login-input-label__wrapper">
                <label
                  for="email"
                  class="login-label"
                >
                  E-mail
                </label>
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  class="login-input"
                  @focus="errorText=''"
                  @keydown.enter="logInUserByEmailAndPassword"
                >
              </div>
              <p
                v-if="errors[0]"
                class="login-error__text text-center mt-5"
              >
                {{ errors[0] }}
              </p>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{errors}"
              name="password"
              rules="required"
            >
              <div class="login-input-label__wrapper">
                <label
                  for="password"
                  class="login-label"
                >Password
                </label>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  class="login-input"
                  @focus="errorText=''"
                  @keydown.enter="logInUserByEmailAndPassword"
                >
              </div>
              <p
                v-if="errors[0]"
                class="login-error__text text-center mt-5"
              >
                {{ errors[0] }}
              </p>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="errorText">
          <v-col class="text-center">
            <p class="login-error__text">
              {{ errorText }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center mt-2">
            <custom-button
              text="Log In"
              :disabled="invalid"
              @click="logInUserByEmailAndPassword"
            />
          </v-col>
        </v-row>
      </validation-observer>
      <v-row class="mt-4">
        <v-col class="text-center">
          <button
            class="login-text__button"
            @click="loginByGoogle"
          >
            Log In by Google Account
          </button>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <button
            class="login-text__button text-decoration-underline"
            @click="$router.push({name: 'ForgotPassword'})"
          >
            I forgot my password
          </button>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <button
            class="login-text__button"
            @click="$router.push({name: 'SignUp'})"
          >
            Sign Up
          </button>
        </v-col>
      </v-row>
    </v-container>
    <div class="common-background__svg">
      <img :src="backgroundImage">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CustomButton from '@/components/CustomButton'
import { loginByEmail, authenticationByGoogle } from '@/authentication.js'
import { getAnalytics, logEvent } from 'firebase/analytics'
import get from 'lodash/get'

export default {
  components: {
    CustomButton
  },

  props: {
    backgroundImage: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    email: '',
    password: '',
    errorText: null
  }),

  computed: {
    ...mapGetters({
      userUid: 'GLOBAL/GET_USER_UID'
    })
  },

  mounted () {
    const analytics = getAnalytics()
    logEvent(analytics, 'LoginPage')
  },

  methods: {
    ...mapMutations({
      setUserData: 'GLOBAL/SET_USER_DATA'
    }),

    async logInUserByEmailAndPassword () {
      try {
        const user = await loginByEmail(this.userUid, this.email, this.password)
        this.actionAfterLogin(user)
      } catch (error) {
        this.errorText = error
      }
    },

    actionAfterLogin (user) {
      this.setUserData({
        user: user
      })
      const isUserEmailVerified = get(user, 'emailVerified', false)
      if (isUserEmailVerified) this.$router.push({ name: 'Account' })
      else this.$router.push({ name: 'EmailNotVerified' })
    },

    async loginByGoogle () {
      try {
        const user = await authenticationByGoogle(this.userUid)
        this.actionAfterLogin(user)
      } catch (error) {
        this.errorText = error
      }
    }
  }
}
</script>
