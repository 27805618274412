<template>
  <div
    v-if="products || type === 'FREE'"
    :class="sectionClass"
  >
    <v-row>
      <v-col>
        <h3 :class="titleClass">
          {{ title }}
        </h3>
      </v-col>
    </v-row>
    <v-row v-if="type === 'UPGRADE'">
      <v-col>
        <div class="d-flex justify-center">
          <p class="single-plan__toggle-text single-plan__toggle-text--left mr-3">
            monthly
          </p>
          <toggle-switch
            :value="isShowAnnually"
            class="single-plan__toggle"
            @update:value="$emit('update:isShowAnnually', $event)"
          />
          <div class="align-self-end ml-3">
            <p class="single-plan__toggle-text">
              annually
            </p>
            <p class="single-plan-subtitle__save-plan">
              (save 37% <br>with this plan)
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="single-plan__price text-center">
          <span class="single-plan__price--value">
            <sup>€ </sup>{{ price }}</span> /month
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ul class="single-plan__list">
          <li
            v-for="(item, index) in textPoints"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <custom-button
          :text="buttonText"
          :class="['mb-4', type === 'FREE' ? 'mt-8' : 'mt-2']"
          @click="choosePlan"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getProducts, createCheckoutSession } from '@stripe/firestore-stripe-payments'
import { firebaseConfig } from '@/firebase_config'
import CustomButton from '@/components/CustomButton.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import get from 'lodash/get'
import { mapMutations } from 'vuex'
export default {
  components: {
    CustomButton,
    ToggleSwitch
  },

  props: {
    type: {
      type: String,
      default: 'FREE'
    },

    textPoints: {
      type: Array,
      default: () => []
    },

    buttonText: {
      type: String,
      default: ''
    },

    isShowAnnually: {
      type: Boolean,
      default: true
    },

    payments: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    price: '0',
    products: null,
    currentPlanPriceId: null
  }),

  computed: {
    sectionClass () {
      return ['single-plan__section',
        this.type === 'FREE' ? 'single-plan__section-light' : 'single-plan__section-dark']
    },

    titleClass () {
      return [this.type === 'UPGRADE' ? 'single-plan__title--upgrade' : 'single-plan__title', 'text-center', 'mt-3']
    },

    title () {
      return this.type === 'UPGRADE'
        ? this.type.toLowerCase() + ' to pro' : this.type.toLowerCase() + ' plan'
    },

    stripePrices () {
      return get(this.products, '[0].prices', null)
    }
  },

  watch: {
    isShowAnnually (value) {
      this.setPrice(value)
    }
  },

  async mounted () {
    if (this.type !== 'FREE') {
      this.products = await getProducts(this.payments, {
        includePrices: true,
        activeOnly: true
      })
      this.setPrice(this.isShowAnnually)
    }
    this.$emit('ready')
  },

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    }),

    setPrice (isShowAnnually) {
      if (this.type !== 'FREE') {
        this.price = isShowAnnually ? '5' : '8'
        const currentPlanPrice = this.stripePrices.filter(item => item.interval === (isShowAnnually ? 'year' : 'month'))
        this.currentPlanPriceId = get(currentPlanPrice, '[0].id', null)
      }
    },

    async choosePlan () {
      if (this.type === 'FREE') {
        window.open('https://install.clocktrace.com/get-permanent-version-ct-windows', '_blank')
      } else this.openPayment()
    },

    async openPayment () {
      this.setWaitForJobCompletion(true)
      const session = await createCheckoutSession(this.payments, {
        price: this.currentPlanPriceId,
        mode: 'subscription',
        success_url: `https://${firebaseConfig.authDomain}/account`,
        cancel_url: `https://${firebaseConfig.authDomain}/select-plan`,
        automatic_tax: {
          enable: true
        },
        tax_id_collection: {
          enabled: true
        },
        allow_promotion_codes: true
      })
      window.location.assign(session.url)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
