<template>
  <div>
    <div v-if="isActionKeyCorrect === true">
      <reset-password-wrapper
        :action-code="actionCode"
      />
    </div>
    <div v-if="isActionKeyCorrect === false">
      <v-row>
        <v-col>
          <h2 class="mt-6 mb-9">
            Try resetting your password again
          </h2>
          <p class="login-error__text text-center mb-3">
            {{ errorText }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <button
            class="login-text__button text-decoration-underline"
            @click="$router.push({ name: 'ForgotPassword' })"
          >
            Reset your password
          </button>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ResetPasswordWrapper from '@/components/ResetPasswordWrapper.vue'
import get from 'lodash/get'
import { verifyResetCodeFromEmail } from '@/authentication.js'
import { mapMutations } from 'vuex'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
  components: {
    ResetPasswordWrapper
  },

  data: () => ({
    isActionKeyCorrect: null,
    errorText: null,
    actionCode: null
  }),

  async mounted () {
    this.setWaitForJobCompletion(true)

    const analytics = getAnalytics()
    logEvent(analytics, 'ResetPasswordPage')

    const uriParams = this.$route.query
    this.actionCode = get(uriParams, 'oobCode', null)
    try {
      const email = await verifyResetCodeFromEmail(this.actionCode)
      if (email) this.isActionKeyCorrect = true
    } catch (error) {
      this.isActionKeyCorrect = false
      this.errorText = error
    }
    this.setWaitForJobCompletion(false)
  },

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
