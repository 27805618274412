<template>
  <div>
    <v-container class="common-page__container">
      <v-row>
        <v-col>
          <h2>Check your inbox to activate your account.</h2>
        </v-col>
      </v-row>
    </v-container>
    <div class="common-background__svg">
      <img :src="backgroundImage">
    </div>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from 'firebase/analytics'
export default {
  props: {
    backgroundImage: {
      type: String,
      default: ''
    }
  },

  mounted () {
    const analytics = getAnalytics()
    logEvent(analytics, 'TheNotVerifiedEmail')
  }
}
</script>

<style lang="scss" scoped>

</style>
