import {
  getAuth,
  signInWithPopup,
  indexedDBLocalPersistence,
  setPersistence,
  signOut,
  GoogleAuthProvider,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendEmailVerification,
  applyActionCode
} from 'firebase/auth'
import get from 'lodash/get'

export const authenticationByGoogle = async (uid) => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    prompt: 'select_account'
  })
  const auth = getAuth()
  try {
    await setPersistence(auth, indexedDBLocalPersistence)
    const credential = await signInWithPopup(auth, provider)
    await checkUserEmailIsVerified(credential.user)
    if (uid) await getAuthToken(credential.user, uid)
    return credential.user
  } catch (error) {
    throw errorHandler(error)
  }
}

export const createUserAccountByEmail = async (uid, email, password) => {
  const auth = getAuth()
  try {
    await setPersistence(auth, indexedDBLocalPersistence)
    const credential = await createUserWithEmailAndPassword(auth, email, password)
    await checkUserEmailIsVerified(credential.user)
    if (uid) await getAuthToken(credential.user, uid)
    return credential.user
  } catch (error) {
    throw errorHandler(error)
  }
}

export const loginByEmail = async (uid, email, password) => {
  const auth = getAuth()
  try {
    await setPersistence(auth, indexedDBLocalPersistence)
    const credential = await signInWithEmailAndPassword(auth, email, password)
    await checkUserEmailIsVerified(credential.user)
    if (uid) await getAuthToken(credential.user, uid)
    return credential.user
  } catch (error) {
    throw errorHandler(error)
  }
}

export const checkUserEmailIsVerified = async (user) => {
  const isVerifiedUserEmail = get(user, 'emailVerified', null)
  if (!isVerifiedUserEmail) return await sendEmailForVerification(user)
}

export const sendEmailForVerification = async (user) => {
  try {
    await sendEmailVerification(user)
    return true
  } catch (error) {
    throw errorHandler(error)
  }
}

export const confirmEmail = async (actionCode) => {
  const auth = getAuth()
  try {
    await applyActionCode(auth, actionCode)
    return true
  } catch (error) {
    throw errorHandler(error)
  }
}

export const userIsLoggedIn = async () => {
  const auth = getAuth()
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) resolve(user)
      else resolve(null)
    })
  })
}

export const signOutApp = async () => {
  const auth = getAuth()
  try {
    await signOut(auth)
    return true
  } catch (error) {
    return false
  }
}

export const sendResetPasswordEmail = async (email) => {
  const auth = getAuth()
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (error) {
    throw errorHandler(error)
  }
}

export const verifyResetCodeFromEmail = async (actionCode) => {
  const auth = getAuth()
  try {
    const email = await verifyPasswordResetCode(auth, actionCode)
    return email
  } catch (error) {
    throw errorHandler(error)
  }
}

export const setNewPassword = async (actionCode, newPassword) => {
  const auth = getAuth()
  try {
    await confirmPasswordReset(auth, actionCode, newPassword)
    return true
  } catch (error) {
    throw errorHandler(error)
  }
}

export const errorHandler = (error, valueName = 'user') => {
  console.warn('Auth error:', error.code)
  if (error.code === 'auth/cancelled-popup-request') return null
  if (error.code === 'auth/popup-closed-by-user') return null
  if (error.code === 'auth/account-exists-with-different-credential') {
    return new Error('You have account for this email but on register you used other authentication service.')
  }

  if (error.code === 'auth/user-not-found') return new Error('You don\'t have an account.')
  if (error.code === 'auth/wrong-password') return new Error('Wrong password. Try again.')
  if (error.code === 'auth/email-already-in-use') return new Error('Account for this email address already exists.')
  if (error.code === 'auth/invalid-email') return new Error('Invalid email address.')
  if (error.code === 'auth/internal-error') return new Error('Your request is invalid.')
  if (error.code === 'auth/expired-action-code') return new Error('Your request has expired.')
  if (error.code === 'auth/invalid-action-code') {
    return new Error('Your request is invalid or the link has already been used.')
  }
  if (error.code === 'auth/user-disabled') return new Error('Your account is disabled.')
  if (error.code === 'auth/invalid-req-type') return null
}

export const getAuthToken = async (user, uid) => {
  const token = await user.getIdToken()
  return await fetch(`https://us-central1-timesheetkiller-45533.cloudfunctions.net/createAuthToken?ot-auth-code=${uid}&id-token=${token}`)
}
