import Vue from 'vue'
import Vuex from 'vuex'
import { GLOBAL } from './global'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { key: 'main' },
  modules: {
    GLOBAL
  }
})
