<template>
  <div>
    <v-container class="mb-5 common-page__container">
      <v-row v-if="!isActiveSubscription">
        <v-col class="d-flex justify-center">
          <single-plan-section
            v-if="payments"
            :is-show-annually.sync="isShowAnnually"
            type="UPGRADE"
            :text-points="proPlanTextPoints"
            :payments="payments"
            button-text="Get started"
            @ready="mountedProPlan=true"
          />
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col class="d-flex flex-column align-center mt-16 pt-16">
            <custom-button
              text="Download ClockTrace"
              class="custom-button__big my-6"
              @click="downloadApp"
            />
            <custom-button
              text="Manage subscription"
              class="custom-button__transparent custom-button__big my-6"
              @click="manageSubscription"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div
      v-if="isActiveSubscription || mountedProPlan"
      :class="backgroundSvgClass"
    >
      <img :src="backgroundImage">
    </div>
  </div>
</template>

<script>
import { getApp } from '@firebase/app'
import { httpsCallable, getFunctions } from '@firebase/functions'
import { getStripePayments, getCurrentUserSubscriptions } from '@stripe/firestore-stripe-payments'
import { firebaseConfig } from '../firebase_config'
import { mapGetters, mapMutations } from 'vuex'
import SinglePlanSection from '@/components/SinglePlanSection.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
  components: {
    SinglePlanSection,
    CustomButton
  },

  props: {
    backgroundImage: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    isShowAnnually: true,
    firebaseApp: null,
    payments: null,
    firebaseFunctions: null,
    userSubscription: [],
    proPlanTextPoints: ['everything from the free plan',
      'timesheet export',
      'adjusting logged time',
      'expanding logged time to expected worktime',
      'assigning any activity to any project',
      'activities report export'],
    mountedProPlan: false
  }),

  computed: {
    ...mapGetters({
      userData: 'GLOBAL/GET_USER_DATA'
    }),

    isActiveSubscription () {
      return this.userSubscription.filter(subscription => subscription.status === 'active').length > 0
    },

    backgroundSvgClass () {
      return ['common-background__svg',
        !this.isActiveSubscription ? 'common-background__svg--absolute' : '']
    }
  },

  watch: {
    mountedProPlan (value) {
      if (value) this.setWaitForJobCompletion(false)
    }
  },

  async mounted () {
    this.setWaitForJobCompletion(true)
    const analytics = getAnalytics()
    logEvent(analytics, 'AccountPage')
    this.firebaseApp = getApp()
    this.firebaseFunctions = getFunctions(this.firebaseApp)
    this.payments = getStripePayments(this.firebaseApp, {
      productsCollection: 'products',
      customersCollection: 'customers'
    })
    this.userSubscription = await getCurrentUserSubscriptions(this.payments)
    if (this.isActiveSubscription) this.setWaitForJobCompletion(false)
  },

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    }),

    async manageSubscription () {
      this.setWaitForJobCompletion(true)
      const functionRef = httpsCallable(this.firebaseFunctions,
        'ext-firestore-stripe-payments-createPortalLink')
      const { data } = await functionRef({
        returnUrl: `https://${firebaseConfig.authDomain}`
      })
      window.location.assign(data.url)
    },

    downloadApp () {
      window.open('https://install.clocktrace.com/get-permanent-version-ct-windows', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
