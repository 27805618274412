<template>
  <div v-show="isProPlanMounted">
    <v-container class="mb-8 common-page__container">
      <v-row>
        <v-col>
          <h2>Choose your plan!</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex justify-center">
            <p class="select-plan__toggle-text select-plan__toggle-text--left align-self-end mr-3">
              monthly
            </p>
            <toggle-switch
              :value.sync="isShowAnnually"
              class="select-plan__toggle"
            />
            <div class="align-self-end ml-3">
              <p class="select-plan-subtitle__save-plan">
                (save 37% with this plan)
              </p>
              <p class="select-plan__toggle-text">
                annually
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div
            class="d-flex justify-center"
          >
            <single-plan-section
              type="FREE"
              :text-points="freePlanTextPoints"
              button-text="Try for free"
              class="mr-8 ml-3"
            />
            <single-plan-section
              v-if="payments"
              type="PRO"
              :is-show-annually="isShowAnnually"
              :text-points="proPlanTextPoints"
              :payments="payments"
              button-text="Get started"
              class="ml-8 mr-3"
              @ready="isProPlanMounted=true"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="common-background__svg common-background__svg--absolute">
      <img :src="backgroundImage">
    </div>
  </div>
</template>

<script>
import { getApp } from '@firebase/app'
import { getStripePayments } from '@stripe/firestore-stripe-payments'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import SinglePlanSection from '@/components/SinglePlanSection.vue'
import { mapMutations } from 'vuex'
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
  components: {
    ToggleSwitch,
    SinglePlanSection
  },

  props: {
    backgroundImage: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    isShowAnnually: true,
    freePlanTextPoints: [
      'tracking all activities',
      'auto-assigning activities to tasks',
      'full privacy protection',
      'unlimited projects',
      'aggregated reports',
      'incognito mode'
    ],
    proPlanTextPoints: ['everything from the free plan',
      'timesheet export',
      'adjusting logged time',
      'expanding logged time to expected worktime',
      'assigning any activity to any project',
      'activities report export'],
    firebaseApp: null,
    payments: null,
    isProPlanMounted: false

  }),

  watch: {
    isProPlanMounted (value) {
      if (value) this.setWaitForJobCompletion(false)
    }
  },

  async mounted () {
    this.setWaitForJobCompletion(true)
    const analytics = getAnalytics()
    logEvent(analytics, 'SelectPlanPage')
    this.firebaseApp = getApp()
    this.payments = getStripePayments(this.firebaseApp, {
      productsCollection: 'products',
      customersCollection: 'customers'
    })
  },

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
