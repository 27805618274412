<template>
  <div>
    <div v-if="emailConfirmed === true">
      <v-row>
        <v-col>
          <h2>
            You’ve verified your email address successfully.
            <img
              :src="CheckMark"
              class="ml-1"
              width="20"
              height="20"
            >
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="text-center white--text mt-1">
            See you in: {{ counter }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <custom-button
            text="Click here"
            class="custom-button__transparent"
            @click="$router.push({ name: nextPage })"
          />
          <p class="mt-2 white--text">
            To go to the final site right away.
          </p>
        </v-col>
      </v-row>
    </div>
    <div v-if="emailConfirmed === false">
      <v-row>
        <v-col>
          <h2>Your email address has not been confirmed. Try again.</h2>
        </v-col>
      </v-row>
      <v-row v-if="errorText">
        <v-col>
          <p class="login-error__text text-center">
            {{ errorText }}
          </p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { confirmEmail } from '@/authentication'
import { mapGetters, mapMutations } from 'vuex'
import get from 'lodash/get'
import CheckMark from '@/assets/icons/check_mark.png'
import { getAnalytics, logEvent } from 'firebase/analytics'
export default {

  components: {
    CustomButton
  },

  data: () => ({
    CheckMark,
    actionCode: null,
    emailConfirmed: null,
    errorText: '',
    counter: 5,
    counterTimeout: null
  }),

  computed: {
    ...mapGetters({
      userData: 'GLOBAL/GET_USER_DATA'
    }),

    nextPage () { return Object.keys(this.userData).length !== 0 ? 'SelectPlan' : 'Login' }
  },

  watch: {
    emailConfirmed (newValue) {
      if (newValue) {
        this.counterTimeout = setInterval(() => {
          this.counter--
        }, 1000)
      }
    },

    counter (newValue) {
      if (newValue === 0) {
        clearInterval(this.counterTimeout)
        this.$router.push({ name: this.nextPage })
      }
    }
  },

  async mounted () {
    this.setWaitForJobCompletion(true)

    const analytics = getAnalytics()
    logEvent(analytics, 'VerifyEmail')

    const uriParams = this.$route.query
    this.actionCode = get(uriParams, 'oobCode', null)

    try {
      const value = await confirmEmail(this.actionCode)
      if (value) this.emailConfirmed = true
    } catch (error) {
      this.emailConfirmed = false
      this.errorText = error
    }
    this.setWaitForJobCompletion(false)
  },

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    })
  }

}
</script>

<style lang="scss" scoped>

</style>
