<template>
  <validation-observer v-slot="{invalid}">
    <v-row>
      <v-col>
        <validation-provider
          v-slot="{errors}"
          name="password"
          rules="required|min:8|hasUpperCase|hasLowerCase|hasDigit|hasSpecialCharacter"
        >
          <div class="login-input-label__wrapper">
            <label
              for="password"
              class="login-label"
            >Password</label>
            <input
              id="password"
              ref="password"
              v-model="password"
              type="password"
              class="login-input"
              @focus="errorText = ''"
              @keydown.enter="invalid ? null : setPassword()"
            >
          </div>
          <p
            v-if="errors[0]"
            class="login-error__text text-center mt-5"
          >
            {{ errors[0] }}
          </p>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <validation-provider
          v-slot="{errors}"
          name="confirmPassword"
          rules="required|confirmed:password"
        >
          <div class="login-input-label__wrapper">
            <label
              for="confirmPassword"
              class="login-label"
            >
              Confirm Password
            </label>
            <input
              id="confirmPassword"
              v-model="confirmPassword"
              type="password"
              class="login-input"
              @focus="errorText = ''"
              @keydown.enter="invalid ? null : setPassword()"
            >
          </div>
          <p
            v-if="errors[0]"
            class="login-error__text text-center mt-5"
          >
            {{ errors[0] }}
          </p>
        </validation-provider>
      </v-col>
    </v-row>
    <v-row v-if="errorText">
      <v-col>
        <p class="login-error__text text-center">
          {{ errorText }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center my-2">
        <custom-button
          text="Reset password"
          :disabled="invalid"
          @click="setPassword"
        />
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { setNewPassword } from '@/authentication.js'
import { mapMutations } from 'vuex'

export default {
  components: {
    CustomButton
  },

  props: {
    actionCode: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    password: '',
    confirmPassword: '',
    errorText: ''
  }),

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    }),

    async setPassword () {
      this.setWaitForJobCompletion(true)
      try {
        const value = await setNewPassword(this.actionCode, this.password)
        if (value) this.$emit('newPasswordHasBeenSet', true)
      } catch (error) {
        this.errorText = error
        this.setWaitForJobCompletion(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
