import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const vuetifySettings = {
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    themes: {
      light: {
        primary: '#ffffff'
      }
    }
  }
}

export default new Vuetify({
  ...vuetifySettings
})
