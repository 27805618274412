import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, min, confirmed } from 'vee-validate/dist/rules'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('email', {
  ...email,
  message: 'Email is not valid.'
})

extend('required', {
  ...required,
  message: 'This field is required.'
})

extend('min', {
  ...min,
  message: '{_field_} must be at least 8 characters long.'
})

extend('hasUpperCase', {
  validate: (value) => value.match(/[A-Z]/g) !== null,
  message: '{_field_} must contain uppercase letter.'
})

extend('hasLowerCase', {
  validate: (value) => value.match(/[a-z]/g) !== null,
  message: '{_field_} must contain lowercase letter.'
})

extend('hasDigit', {
  validate: (value) => value.match(/[0-9]/g) !== null,
  message: '{_field_} must contain digit.'
})

extend('hasSpecialCharacter', {
  validate: (value) => value.match(/[#?!@$%^&*-]/g) !== null,
  message: '{_field_} must contain special character.'
})

extend('confirmed', {
  ...confirmed,
  message: 'Password and confirm password field do not match.'
}
)
