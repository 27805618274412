export const firebaseConfig = {
  apiKey: 'AIzaSyC0Stv9DN9oMTAPl9wJqf8Uy14zLjnQMVs',
  authDomain: 'join.timesheetkiller.com',
  databaseURL: 'https://timesheetkiller-45533-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'timesheetkiller-45533',
  storageBucket: 'timesheetkiller-45533.appspot.com',
  messagingSenderId: '168067080199',
  appId: '1:168067080199:web:1a21a7947748278b2b4aa2',
  measurementId: 'G-7XZQ8CRNWW'
}
