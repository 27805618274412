import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'
import vuetify from './plugins/vuetify'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebase_config'
import { getAnalytics } from 'firebase/analytics'
import * as validation from './validation'
Vue.config.productionTip = false

const firebaseApp = initializeApp(firebaseConfig)
getAnalytics(firebaseApp)

new Vue({
  router,
  store,
  vuetify,
  validation,
  render: h => h(App)
}).$mount('#app')
