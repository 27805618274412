<template>
  <v-app>
    <div class="app-main__wrapper d-flex flex-column">
      <div class="d-flex justify-center">
        <img
          :src="clockTraceLogo"
          class="app-main__logo"
        >
      </div>
      <router-view
        v-if="isFirebaseCheckInitialLogin"
        class="common-page__wrapper"
        :background-image="BackgroundImage"
      />
    </div>
    <div
      v-if="showLogOutButton"
      class="text-center mb-8"
    >
      <custom-button
        class="custom-button__transparent--dark"
        text="Log Out"
        @click="logOut"
      />
    </div>

    <div>
      <p class="text-center">
        © {{ new Date().getFullYear() }} ClockTrace made by WEBSENSA Sp. z o..o.
      </p>
    </div>
    <v-overlay :value="waitForJobCompletion">
      <v-progress-circular
        indeterminate
        size="64"
        width="8"
        color="#ffffff"
      />
    </v-overlay>
  </v-app>
</template>

<script>
import get from 'lodash/get'
import { mapGetters, mapMutations } from 'vuex'
import { userIsLoggedIn, getAuthToken, signOutApp } from '@/authentication.js'
import './assets/scss/index.scss'
import clockTraceLogo from '@/assets/icons/clocktrace-logo.png'
import CustomButton from '@/components/CustomButton.vue'
import BackgroundImage from '@/assets/icons/background-main.svg'

export default {
  name: 'App',
  components:
    {
      CustomButton
    },

  data: () => ({
    overlayTimeout: null,
    clockTraceLogo,
    BackgroundImage
  }),

  computed: {
    ...mapGetters({
      userUid: 'GLOBAL/GET_USER_UID',
      userData: 'GLOBAL/GET_USER_DATA',
      isFirebaseCheckInitialLogin: 'GLOBAL/GET_IS_FIREBASE_CHECK_INITIAL_LOGIN',
      waitForJobCompletion: 'GLOBAL/GET_WAIT_FOR_JOB_COMPLETION'
    }),

    emailValue () { return get(this.userData, 'user.providerData[0].email', null) },

    showLogOutButton () { return Object.keys(this.userData).length !== 0 }
  },

  watch: {
    waitForJobCompletion (newValue) {
      if (newValue) {
        this.overlayTimeout = setTimeout(() => {
          this.setWaitForJobCompletion(false)
          clearTimeout(this.overlayTimeout)
        }, 10000)
      }
    }
  },

  async mounted () {
    this.setWaitForJobCompletion(true)
    const queryUid = this.$route.query.uid
    this.setUserUid(queryUid || null)
    await this.isUserSignedIn()
  },

  created () {
    document.title = 'ClockTrace'
  },

  methods: {
    ...mapMutations({
      setUserUid: 'GLOBAL/SET_USER_UID',
      setUserData: 'GLOBAL/SET_USER_DATA',
      setIsFirebaseCheckInitialLogin: 'GLOBAL/SET_IS_FIREBASE_CHECK_INITIAL_LOGIN',
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    }),

    async isUserSignedIn () {
      const user = await userIsLoggedIn()
      if (user) await this.actionAfterLogin(user)
      else if (this.$route.path !== '/login' &&
      this.$route.path !== '/action' &&
      this.$route.path !== '/signup') {
        this.$router.push({ name: 'Login' })
      }
      this.setIsFirebaseCheckInitialLogin(true)
      this.setWaitForJobCompletion(false)
    },

    async actionAfterLogin (user) {
      this.setUserData({ user })
      if (this.userUid) await getAuthToken(user, this.userUid)
      this.redirectToViewsAfterLogin(user)
    },

    redirectToViewsAfterLogin (user) {
      const isUserEmailVerified = get(user, 'emailVerified', null)
      if (isUserEmailVerified) {
        if (this.$route.path === '/signup') this.$router.push({ name: 'SelectPlan' })
        if (this.$route.path === '/login') this.$router.push({ name: 'Account' })
      } else {
        if (this.$route.path !== '/email-not-verified' &&
        this.$route.path !== '/action') this.$router.push({ name: 'EmailNotVerified' })
      }
    },

    async logOut () {
      await signOutApp()
      this.setUserData({})
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
