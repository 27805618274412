import Vue from 'vue'
import VueRouter from 'vue-router'
import TheLoginPage from './views/TheLoginPage.vue'
import TheSignUpPage from './views/TheSignUpPage.vue'
import TheAccountPage from './views/TheAccountPage.vue'
import TheSelectPlan from './views/TheSelectPlan.vue'
import TheForgotPassword from './views/TheForgotPassword.vue'
import TheActionPage from './views/TheActionPage.vue'
import TheNotVerifiedEmail from './views/TheNotVerifiedEmail.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: TheLoginPage
    },
    {
      path: '/account',
      name: 'Account',
      component: TheAccountPage
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: TheSignUpPage
    },
    {
      path: '/select-plan',
      name: 'SelectPlan',
      component: TheSelectPlan
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: TheForgotPassword
    },
    {
      path: '/action',
      name: 'ActionPage',
      component: TheActionPage
    },
    {
      path: '/email-not-verified',
      name: 'EmailNotVerified',
      component: TheNotVerifiedEmail
    }
  ]
})

export default router
