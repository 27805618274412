import Vue from 'vue'

export const GLOBAL = {
  namespaced: true,

  state: {
    isFirebaseCheckInitialLogin: false,
    userData: {},
    uid: {},
    waitForJobCompletion: false
  },

  mutations: {
    SET_IS_FIREBASE_CHECK_INITIAL_LOGIN: (state, value) => { Vue.set(state, 'isFirebaseCheckInitialLogin', value) },

    SET_USER_DATA: (state, value) => { Vue.set(state, 'userData', value) },

    SET_USER_UID: (state, value) => { Vue.set(state, 'uid', value) },

    SET_WAIT_FOR_JOB_COMPLETION: (state, value) => { Vue.set(state, 'waitForJobCompletion', value) }
  },

  getters: {
    GET_IS_FIREBASE_CHECK_INITIAL_LOGIN: state => state.isFirebaseCheckInitialLogin,

    GET_USER_DATA: state => state.userData,

    GET_USER_UID: state => state.uid,

    GET_WAIT_FOR_JOB_COMPLETION: state => state.waitForJobCompletion
  }
}
