<template>
  <div>
    <v-row>
      <v-col>
        <h2>Invalid mode type</h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from 'firebase/analytics'

export default {
  mounted () {
    const analytics = getAnalytics()
    logEvent(analytics, 'InvalidModeType')
  }
}
</script>

<style lang="scss" scoped>

</style>
