<template>
  <div>
    <v-container>
      <component :is="componentToLoad" />
    </v-container>
    <div class="common-background__svg">
      <img :src="backgroundImage">
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import ResetPassword from '@/components/ResetPassword.vue'
import VerifyEmail from '@/components/VerifyEmail.vue'
import InvalidModeType from '@/components/InvalidModeType.vue'

export default {
  components: {
    ResetPassword,
    VerifyEmail,
    InvalidModeType
  },

  props: {
    backgroundImage: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    componentToLoad: null
  }),

  mounted () {
    const uriParams = this.$route.query
    const actionMode = get(uriParams, 'mode', null)
    this.setComponentToLoad(actionMode)
  },

  methods: {
    setComponentToLoad (mode) {
      switch (mode) {
        case 'resetPassword':
          this.componentToLoad = ResetPassword
          break
        case 'verifyEmail':
          this.componentToLoad = VerifyEmail
          break
        default:
          this.componentToLoad = InvalidModeType
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
