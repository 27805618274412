<template>
  <label class="toggle-switch__wrapper">
    <input
      :checked="value"
      class="toggle-switch__checkbox"
      type="checkbox"
      @change="$emit('update:value', $event.target.checked)"
    >
    <span class="toggle-switch__slider" />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
