<template>
  <div>
    <v-container class="common-page__container">
      <v-row>
        <v-col cols="12">
          <h2>Only one step to automate your time tracking</h2>
        </v-col>
      </v-row>
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{errors}"
              name="email"
              rules="email|required"
            >
              <div class="login-input-label__wrapper">
                <label
                  for="email"
                  class="login-label"
                >
                  E-mail
                </label>
                <input
                  id="email"
                  v-model="email"
                  type="email"
                  class="login-input"
                  @focus="errorText=''"
                  @keydown.enter="signUp"
                >
              </div>
              <p
                v-if="errors[0]"
                class="login-error__text text-center mt-5"
              >
                {{ errors[0] }}
              </p>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{errors}"
              name="password"
              rules="required|min:8|hasUpperCase|hasLowerCase|hasDigit|hasSpecialCharacter"
            >
              <div class="login-input-label__wrapper">
                <label
                  for="password"
                  class="login-label"
                >Password
                </label>
                <input
                  id="password"
                  ref="password"
                  v-model="password"
                  type="password"
                  class="login-input"
                  @focus="errorText=''"
                  @keydown.enter="signUp"
                >
              </div>
              <p
                v-if="errors[0]"
                class="login-error__text text-center mt-5"
              >
                {{ errors[0] }}
              </p>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <validation-provider
              v-slot="{errors}"
              name="confirmPassword"
              rules="required|confirmed:password"
            >
              <div class="login-input-label__wrapper">
                <label
                  for="confirmPassword"
                  class="login-label"
                >Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  v-model="confirmPassword"
                  type="password"
                  class="login-input"
                  @focus="errorText=''"
                  @keydown.enter="signUp"
                >
              </div>
              <p
                v-if="errors[0]"
                class="login-error__text text-center mt-5"
              >
                {{ errors[0] }}
              </p>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="errorText">
          <v-col class="text-center">
            <p class="login-error__text">
              {{ errorText }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center mt-2">
            <custom-button
              text="Sign Up"
              :disabled="invalid"
              @click="signUp"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <button
              class="login-text__button"
              @click="$router.push({name: 'Login'})"
            >
              Log In
            </button>
          </v-col>
        </v-row>
        <vue-recaptcha
          ref="recaptcha"
          sitekey="6LcLRmEjAAAAAGkt3A3lGDtHY_GrVgclkxXJuYBt"
          size="invisible"
          @verify="recaptchaVerified = true"
          @error="recaptchaVerified = false"
          @expired="recaptchaVerified = false"
        ></vue-recaptcha>
      </validation-observer>
      <v-row>
        <v-col class="text-center mt-2">
          <p class="login-primary__text">
            By signing up you accept our
            <a
              href="https://www.clocktrace.com/terms-of-service"
              target="_blank"
            >Terms of Service</a> and
            <a
              href="https://www.clocktrace.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy</a>.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div class="common-background__svg">
      <img :src="backgroundImage">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CustomButton from '@/components/CustomButton'
import { createUserAccountByEmail } from '@/authentication.js'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { VueRecaptcha } from 'vue-recaptcha'
import get from 'lodash/get'

export default {
  components: {
    CustomButton,
    VueRecaptcha
  },

  props: {
    backgroundImage: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    email: '',
    password: '',
    confirmPassword: '',
    errorText: null,
    recaptchaVerified: false,
    waitToVerified: false
  }),

  computed: {
    ...mapGetters({
      userUid: 'GLOBAL/GET_USER_UID'
    }),

    headerText () { return this.isSignUp ? 'Only one step to automate your time tracking' : 'Welcome back' }
  },

  watch: {
    recaptchaVerified (newValue) {
      if (this.waitToVerified && newValue) {
        this.signUp()
        this.waitToVerified = false
      }
    }
  },

  mounted () {
    const analytics = getAnalytics()
    logEvent(analytics, 'SignUpPage')
  },

  methods: {
    ...mapMutations({
      setUserData: 'GLOBAL/SET_USER_DATA'
    }),

    async signUp () {
      if (this.recaptchaVerified) {
        try {
          const user = await createUserAccountByEmail(this.userUid, this.email, this.password)
          this.actionAfterLogin(user)
        } catch (error) {
          this.errorText = error
        }
      } else {
        this.$refs.recaptcha.execute()
        this.waitToVerified = true
      }
    },

    actionAfterLogin (user) {
      this.setUserData({
        user: user
      })
      const isUserEmailVerified = get(user, 'emailVerified', null)
      if (isUserEmailVerified) this.$router.push({ name: 'SelectPlan' })
      else this.$router.push({ name: 'EmailNotVerified' })
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
