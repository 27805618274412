<template>
  <div>
    <div v-if="!isNewPasswordSet">
      <v-row>
        <v-col>
          <h2 class="my-6">
            Enter your new password
          </h2>
        </v-col>
      </v-row>
      <reset-password-form
        :action-code="actionCode"
        @newPasswordHasBeenSet="newPasswordHasBeenSet"
      />
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <h2>Your password has been successfully changed</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <button
            class="login-text__button text-decoration-underline"
            @click="$router.push({ name: 'Login' })"
          >
            Log In
          </button>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ResetPasswordForm from './ResetPasswordForm.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    ResetPasswordForm
  },

  props: {
    actionCode: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    isNewPasswordSet: false
  }),

  methods: {
    ...mapMutations({
      setWaitForJobCompletion: 'GLOBAL/SET_WAIT_FOR_JOB_COMPLETION'
    }),

    newPasswordHasBeenSet () {
      this.isNewPasswordSet = true
      this.setWaitForJobCompletion(false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
